// NOTE: housing_scene とは別のAPI、モデルだけど将来的に housing_worlds に統一する
//       housing_worlds = アウトゲームでのみ使う情報
//       housing_scene = インゲーム側でも使う情報

import type { InjectionKey, DeepReadonly } from 'vue'

// repository
import { postHousingWorldsListResponse } from './repository/useHousingWorldsRepository'
import { useRepositoryFactory } from '@/composables/repository/useRepositoryFactory'

// modules
import { isValueOf } from '@/utils/zod'

// models
import {
  HousingWorld,
  getHousingWorldResponse,
  getHousingWorldsResponse,
  GetHousingWorldsRequest,
  HousingWorldSortData,
  SortType,
  SORT_TYPE,
} from '@/models/housingWorld'
import { ProfileData } from '@/models/profiles'

// composables
import { isFetchError } from '@/composables/repository/useOhmyfetch'

export const useHousingWorlds = () => {
  const repositoryFactory = useRepositoryFactory()
  const housingRepository = repositoryFactory.get('housingWorlds')
  const i18n = useI18n()

  type HousingState = {
    housingWorlds: HousingWorld[] // 詳細表示しているルーム
    officialHousingWorlds: HousingWorld[] // 公式ルーム一覧
    currentHousingWorld: HousingWorld | null // 詳細表示しているルーム
  }

  const state = useState<HousingState>('housing-world-state', () => ({
    housingWorlds: [],
    officialHousingWorlds: [],
    currentHousingWorld: null, // 詳細表示しているルーム
  }))

  // ▼▼▼ ソート用 ▼▼▼
  const sortType = ref<SortType>('newer')
  const sortItems: HousingWorldSortData[] = [
    {
      value: SORT_TYPE.NEWER,
      text: i18n.t('sort.housing-world.newer'),
    },
    {
      value: SORT_TYPE.OLDER,
      text: i18n.t('sort.housing-world.older'),
    },
    {
      value: SORT_TYPE.POPULAR,
      text: i18n.t('sort.housing-world.popular'),
    },
    {
      value: SORT_TYPE.VISITED,
      text: i18n.t('sort.housing-world.visited'),
    },
    {
      value: SORT_TYPE.FAVORITE,
      text: i18n.t('sort.housing-world.favorite'),
    },
  ]
  // ▲▲▲ ソート用 ▲▲▲

  // ▼▼▼ pager情報 ▼▼▼
  const limit = ref(28)
  const offset = ref(0)
  const total = ref(0)
  // ▲▲▲ pager情報 ▲▲▲

  const initHousing = () => {
    state.value.housingWorlds = []
    state.value.officialHousingWorlds = []
    state.value.currentHousingWorld = null
  }

  const getHousingWorlds = async (
    limit: number,
    offset: number
  ): Promise<HousingWorld[]> => {
    try {
      const response = await housingRepository.get.getHousingWorlds({
        limit,
        offset,
      })

      if (!response) {
        throw new Error('response is empty.')
      }

      if (!isValueOf(getHousingWorldsResponse, response)) {
        console.error('An API response is different.')
      }

      state.value.housingWorlds = response.housingWorlds || null

      return response.housingWorlds
    } catch (e: unknown) {
      // 一つもない時空配列を返す
      if (isFetchError(e) && e.response?.status === 404) {
        return []
      }
      throw e
    }
  }

  const getHousingWorld = async (
    worldId: string
  ): Promise<HousingWorld | null> => {
    const response = await housingRepository.get.getHousingWorld({
      worldId,
    })

    if (!response) {
      throw new Error('response is empty.')
    }

    if (!isValueOf(getHousingWorldResponse, response)) {
      console.error('An API response is different.')
    }

    if (!response.housingWorld) {
      throw new Error('Failed to get Room')
    }

    state.value.currentHousingWorld = response.housingWorld

    return response.housingWorld
  }

  /**
   * インゲームURL取得
   */
  const getHousingWorldURL = (worldId: string, channelId?: string) => {
    const config = useRuntimeConfig()
    return `${config.public.NUXT_ENV_URL}/myroom/maker/?worldid=${worldId}${
      channelId ? '&roomid=' + channelId : ''
    }`
  }

  const getHousingWorldWithPager = async (
    page: number,
    options?: {
      sort?: SortType
      search?: string
      filter?: 'official' | 'user'
    }
  ) => {
    try {
      offset.value = page * limit.value - limit.value

      const params: GetHousingWorldsRequest = {
        limit: limit.value,
        offset: offset.value,
      }

      if (options?.sort) {
        sortType.value = options.sort
        params.sort = options.sort
      }

      if (options?.search) {
        params.search = options.search
      }

      if (options?.filter) {
        params.filter = options.filter
      }

      const response = await housingRepository.get.getHousingWorlds(params)

      if (!response) {
        throw new Error('response is empty.')
      }

      if (!isValueOf(getHousingWorldsResponse, response)) {
        console.error('An API response is different.')
      }

      state.value.housingWorlds = response.housingWorlds || null
      total.value = response.housingWorldCount || 0
      return response.housingWorlds
    } catch (e: unknown) {
      // 一つもない時空配列を返す
      if (isFetchError(e) && e.response?.status === 404) {
        return []
      }
      throw e
    }
  }

  const getRecommendedHousingWorlds = async (): Promise<HousingWorld[]> => {
    try {
      const response = await housingRepository.get.getRecommendedHousingWorlds()

      if (!response) {
        throw new Error('response is empty.')
      }

      if (!isValueOf(getHousingWorldsResponse, response)) {
        console.error('An API response is different.')
      }

      total.value = response.housingWorlds.length
      state.value.housingWorlds = response.housingWorlds || null

      return response.housingWorlds
    } catch (e: unknown) {
      // 一つもない時空配列を返す
      if (isFetchError(e) && e.response?.status === 404) {
        return []
      }
      throw e
    }
  }

  const getHousingOfficialWorlds = async (
    limit: number,
    offset: number
  ): Promise<HousingWorld[]> => {
    try {
      const response = await housingRepository.get.getHousingWorlds({
        limit,
        offset,
      })

      if (!response) {
        throw new Error('response is empty.')
      }

      if (!isValueOf(getHousingWorldsResponse, response)) {
        console.error('An API response is different.')
      }

      state.value.officialHousingWorlds = response.housingWorlds || null

      return response.housingWorlds
    } catch (e: unknown) {
      // 一つもない時空配列を返す
      if (isFetchError(e) && e.response?.status === 404) {
        return []
      }
      throw e
    }
  }

  const housingAndProfileMerge = (
    housingWorldList: DeepReadonly<HousingWorld[]>,
    profileList: DeepReadonly<ProfileData[]>
  ) => {
    return housingWorldList.map((housingWorld) => {
      const profile = profileList.find(
        (profile) => profile.vketId === housingWorld.user.vketId
      )
      return {
        ...housingWorld,
        profile: profile || null,
      }
    })
  }

  /**
   * ハウジングワールド一括取得を実行する
   * @param worldCodeList
   * @returns
   */
  const getHousingWorldsList = async (worldCodeList: string[]) => {
    try {
      const response = await housingRepository.post.getHousingWorldsList({
        worldIds: worldCodeList,
      })

      if (!response) {
        throw new Error('response is empty.')
      }

      if (!isValueOf(postHousingWorldsListResponse, response)) {
        console.error('An API response is different.')
      }

      state.value.housingWorlds = response.housingWorlds || []

      return response.housingWorlds
    } catch (e: unknown) {
      // 一つもない時空配列を返す
      if (isFetchError(e) && e.response?.status === 404) {
        return []
      }
      throw e
    }
  }

  /**
   * ソートタイプを更新する
   */
  const updateSortType = (type: SortType) => {
    sortType.value = type
  }

  return {
    state: readonly(state),
    limit,
    offset,
    total,
    sortItems,
    sortType: readonly(sortType),
    initHousing,
    getHousingWorlds,
    getHousingWorld,
    getHousingWorldURL,
    getHousingWorldWithPager,
    getRecommendedHousingWorlds,
    housingAndProfileMerge,
    getHousingOfficialWorlds,
    getHousingWorldsList,
    updateSortType,
  }
}

export type HousingWorldsComposable = ReturnType<typeof useHousingWorlds>

export const housingWorldsInjectionKey: InjectionKey<HousingWorldsComposable> =
  Symbol('housing-worlds-composable')
